import React, { useState, useEffect } from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PF2MTable from '~/components/PF2MTable';
import {
  getAnomalyTypes,
  getAnomalyLogs,
  getAnomalyLogsCodes,
  // getAnomalyLogsCloud,
  getTransportAnomaly,
} from '~/store/dispatch/actions';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
  getElements,
  getMaterials,
  getSubElements,
} from '~/store/manager/actions';
import AnomalyLogsFilter from './AnomalyLogsFilter';
import AnomalyLogsEditModal from './AnomalyLogsEditModal';
import styles from '../styles';
import { searchLoadList } from '~/store/dailyparts/actions';
import { newMomentDate, parseDate, parseDateToServerFormat } from '~/utils/moment';

const AnomalyLogsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const currentFilter = useSelector(state => state.dispatch.currentAnomalyLogsFilter);
  const anomalyLogs = useSelector(state => state.dispatch.anomalyLogs);
  const [groupedAnomalyLogs, setGroupedAnomalyLogs] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const groupBy = (xs, key) => xs.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

  useEffect(() => {
    const groupedTransport = groupBy(anomalyLogs.filter(i => i.anomalous_table === 1).map(k => ({ ...k, key: k.id })), 'group_id');
    const groupedLoad = groupBy(anomalyLogs.filter(i => i.anomalous_table === 2).map(k => ({ ...k, key: k.id })), 'group_id');
    const groupedCode = groupBy(anomalyLogs.filter(i => i.anomalous_table === 3).map(k => ({ ...k, key: k.id })), 'group_id');
    const groupedCloud = groupBy(anomalyLogs.filter(i => i.anomalous_table === 4).map(k => ({ ...k, key: k.id, hideEdit: true })), 'group_id');
    const normalizeGroup = group => Object.entries(group).map(k => ({
      ...k,
      anomalous_table: k[1][0].anomalous_table,
      anomalous_table_name: k[1][0].anomalous_table_name,
      group_equipament_links_name: k[1][0].group_equipament_links_name,
      equipment_groups_name: k[1][0].equipment_groups_name,
      equipment_type_name: k[1][0].equipment_type_name,
      anomaly_type_name: k[1][0].anomaly_type_name,
      count: k[1].length,
      hideEdit: k[1][0].hideEdit,
      id: k[0] + k[1][0].anomalous_table,
    }));
    const groupedArray = normalizeGroup(groupedTransport)
      .concat(normalizeGroup(groupedLoad))
      .concat(normalizeGroup(groupedCode))
      .concat(normalizeGroup(groupedCloud));
    setGroupedAnomalyLogs(groupedArray);
  }, [anomalyLogs]);

  useEffect(() => {
    const dateFilter = currentFilter.date
      ? parseDate(parseDateToServerFormat(currentFilter.date), true)
      : newMomentDate();
    async function fetchData() {
      dispatch(getSubElements(true));
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getEquipments());
      dispatch(getElements());
      dispatch(getMaterials());
      dispatch(getAnomalyTypes());
      dispatch(getAnomalyLogs(currentFilter));
      dispatch(getAnomalyLogsCodes(currentFilter));
      dispatch(getTransportAnomaly(currentFilter));
      // dispatch(getAnomalyLogsCloud(currentFilter));
      dispatch(searchLoadList({
        startDate: dateFilter,
        endDate: dateFilter,
        hideInterruptedOrDeleted: true,
      }));
    }
    if (!dataLoaded && !modalOpen) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch, currentFilter, modalOpen]);

  const handleClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  useEffect(() => {
    if (currentFilter) {
      setDataLoaded(false);
    }
  }, [currentFilter]);

  const columns = [
    {
      field: 'count',
      title: translate('common:Registers'),
    },
    {
      field: 'equipment_type_name',
      title: translate('common:EquipmentType'),
    },
    {
      field: 'equipment_groups_name',
      title: translate('common:EquipmentGroup'),
    },
    {
      field: 'group_equipament_links_name',
      title: translate('common:Equipment'),
    },
    {
      field: 'anomalous_table_name',
      translateField: true,
      title: translate('common:AnomalousTable'),
    },
  ];

  const renderHeader = () => (
    <div>
      <AnomalyLogsFilter />
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={groupedAnomalyLogs}
        columns={columns}
        updateItem={(row) => {
          setSelectedGroup(row);
          setModalOpen(true);
        }}
        className={classes.table}
        showEditModal
      />
      <Modal
        open={modalOpen}
        onBackdropClick={() => handleClose(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <AnomalyLogsEditModal
            handleClose={handleClose}
            data={selectedGroup[1]}
            anomalousTable={selectedGroup.anomalous_table}
          />
        </div>
      </Modal>
    </div>
  );
};

AnomalyLogsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

AnomalyLogsTab.defaultProps = {};

export default withStyles(styles)(AnomalyLogsTab);
