import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E6E7E8',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
    height: '100vh',
  },
  grid: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 52px - 52px - 150px)',
  },
  containerTypeSelector: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  typeSelector: {
    height: 40,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#CDCDCD',
  },
  label: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  itens: {
    width: '100%',
    height: 'calc(100vh - 280px)',
  },
  scrollbars: {
    border: '1px solid #CDCDCD',
    borderRadius: 4,
    boxSizing: 'border-box',
    minHeight: 260,
  },
  dailyPartsSelection: {
    marginTop: 20,
    textAlign: 'center',
  },
  unSelectedButton: {
    backgroundColor: '#455873',
    color: '#fff',
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  errorMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    color: '#EC3337',
  },
  fab: {
    background: 'none',
    boxShadow: 'none',
    color: '#F2AA00',
    '&:hover': {
      backgroundColor: '#F1F1F1',
    },
  },
  activeRow: {
    backgroundColor: '#b7ffb3',
  },
  inactiveRow: {
    backgroundColor: '#FFE2E2',
  },
  formControl: {
    zIndex: 11,
    marginLeft: 10,
  },
  searchButton: {
    color: '#FFF',
    marginLeft: 10,
  },
};

export default styles;
