import 'moment/locale/pt-br';
import moment from 'moment';
import i18n from '~/i18n';

export const newMomentDate = () => moment().local();
export const parseDate = (date, isServerFormat) => {
  const parsedDate = isServerFormat
    ? moment(date, 'DD/MM/YYYY')
    : moment(date, i18n.t('date:DateFormat'));

  return parsedDate;
};

export const parseDateTime = (date, isServerFormat) => {
  const parsedDate = isServerFormat
    ? moment(date, 'DD/MM/YYYY HH:mm:ss')
    : moment(date, i18n.t('date:DateTimeFormat'));

  return parsedDate;
};

export const parseDateToServerFormat = (date) => {
  let parsedDate = moment(date, 'DD/MM/YYYY');
  if (!parsedDate.isValid()) {
    parsedDate = moment(date, 'MM/DD/YYYY');
  }
  return parsedDate;
};

export const formatDurationAsTime = duration => moment.utc(moment.duration(duration).asMilliseconds()).format('HH:mm:ss');

export const parseTime = date => moment(date, 'HH:mm:ss');
export const parseTimestamp = date => moment.unix(date);

export const formatDate = (date, shouldReturnServerFormat, isServerFormat) => (
  shouldReturnServerFormat
    ? parseDate(date, isServerFormat).format('DD/MM/YYYY')
    : parseDate(date, isServerFormat).format(i18n.t('date:DateFormat'))
);

export const formatDateTime = (date, shouldReturnServerFormat, isServerFormat) => (
  shouldReturnServerFormat
    ? parseDateTime(date, isServerFormat).format('DD/MM/YYYY HH:mm:ss')
    : parseDateTime(date, isServerFormat).format(i18n.t('date:DateTimeFormat'))
);

export const formatDateToCurrentLocale = (date) => {
  const parsedDate = parseDateToServerFormat(date);
  return parsedDate.format(i18n.t('date:DateFormat'));
};

export const formatDateToServerFormat = date => parseDateToServerFormat(date).format('DD/MM/YYYY');

export const formatTime = (date, mask = null) => parseTime(date).format(mask || 'HH:mm:ss');
export const formatTimestamp = date => moment(date).unix();
export const convertTimeStampToMoment = date => moment.unix(date);
export const addSeconds = (date, seconds) => parseTime(date).add(seconds, 'second');
export const addHours = (date, interval) => parseTime(date).add(interval, 'h');
export const verifyTime = time => moment(time, 'HH:mm:ss', true).isValid();
export const subtractDays = (date, days) => moment(date).subtract(days, 'd');
export const subtractDaysFromNow = days => moment().subtract(days, 'd');
export const getBigger = (date1, date2) => (moment(date1).isAfter(date2) ? date1 : date2);
export const getSmaller = (date1, date2) => (moment(date1).isBefore(date2) ? date1 : date2);

/**
 *
 * @param {*} date string date in dd/mm/yyyy
 * @param {*} time string time in HH:mm:ss
 * @param {*} msDeslocate miliseconds to deslocate
 * @returns number timestamp
 */
export const getTimestamp = (date, time, msDeslocate = 0) => {
  const str = time ? `${date} ${time}` : date;
  return Date.parse(str) + msDeslocate;
};

/**
 * function that returns date object moment or Date to timesetamp utc miliseconds
 * @param {Moment | Date} date date value
 * @param {*} msDeslocate value in ms to add the final result
 * @returns timestamp utc in ms
 */
export const getMsUtc = (date, msDeslocate = 0) => {
  if (typeof date === 'number') return date;
  return Date.parse(date) + msDeslocate;
};

export const daysToMiliseconds = days => days * 24 * 60 * 60 * 1000;

/**
 * Transform date of type Date or brazilian string with or without time in timestamp
 * @param {date | string | moment 'dd/mm/yyyy'} date quando string leva o padrão brasileiro
 * @param {string HH:mm:ss} time
 * @returns number timestamp
 */
export function parseDateToTimestamp(date, time) {
  const momentDate = parseDate(date);
  const [hour, minute, second] = time.split(':').map(t => parseInt(t, 10));
  momentDate.set({ hour, minute, second });
  return momentDate.unix();
}

export function parseDateTimeToTimestamp(dateStr, timeStr) {
  const [day, month, year] = dateStr.split('/');
  const [hour, minute, second] = timeStr.split(':');
  const date = new Date(year, month - 1, day, hour, minute, second);
  if (date.toString() === 'Invalid Date') return 0;
  return date.getTime();
}

export function diffTwoDateTimeStr(dateStartStr, timeStartStr, dateEndStr, timeEndStr) {
  const startDate = (typeof dateStartStr === 'string')
    ? dateStartStr
    : new Date(dateStartStr).toLocaleDateString();

  const endDate = (typeof dateEndStr === 'string')
    ? dateEndStr
    : new Date(dateEndStr).toLocaleDateString();

  const timestampStart = parseDateTimeToTimestamp(startDate, timeStartStr);
  const timestampEnd = parseDateTimeToTimestamp(endDate, timeEndStr);
  return timestampEnd - timestampStart;
}
export function timeDiffFormater(miliseconds, format = 'h:m:s.ms') {
  const time = {
    hour: Math.floor(miliseconds / 3600000),
    minute: Math.floor((miliseconds % 3600000) / 60000),
    seconds: Math.floor((miliseconds % 3600000 % 60000) / 1000),
    miliseconds: Math.floor(miliseconds % 3600000 % 60000 % 1000),
  };
  return format
    .replace('h', time.hour.toString().padStart(2, 0))
    .replace('m', time.minute.toString().padStart(2, 0))
    .replace('s', time.seconds.toString().padStart(2, 0))
    .replace('ms', time.miliseconds);
}
