import * as types from './types';
import { remapData, remapDataByMultiKeys } from '~/utils';
import { newMomentDate } from '~/utils/moment';

const EQUIPMENT_STATUS_INITIAL_FILTER = {
  equip_type: 0,
  id_equip_group: 0,
  id_equip: 0,
  code_type: -1,
  id_code_group: 0,
  id_code: 0,
  element_id: 0,
  point_id: 0,
};

const EQUIPMENT_SCHEDULES_INITIAL_FILTER = {
  startDate: newMomentDate(),
  endDate: newMomentDate(),
  equipType: 0,
  equipGroup: 0,
  equip: 0,
  element: 0,
  subElement: 0,
};

const ANOMALY_LOGS_INITIAL_FILTER = {
  startDate: newMomentDate(),
  endDate: newMomentDate(),
  equipType: 0,
  equipGroup: 0,
  equip: 0,
  anomalyType: 0,
  groupCloudPoints: true,
  status: true,
};

const LOAD_MAPPINGS_INITIAL_FILTER = {
  equipment_id: 0,
  material_id: 0,
  element_id: 0,
};

const DISPATCH_OPERATIONAL_CONTROL_FILTERS = {
  start_date: newMomentDate(),
  end_date: newMomentDate(),
};

const INITIAL_STATE = {
  syncStatus: [],
  equipmentStatuses: [],
  currentEquipmentStatus: {},
  originalCurrentEquipmentStatus: {},
  isCurrentEquipmentStatusUpdated: false,
  currentEquipmentStatusFilter: EQUIPMENT_STATUS_INITIAL_FILTER,
  preventiveTypes: [],
  equipmentsSchedules: [],
  anomalyLogs: [],
  anomalyLogsCodes: [],
  anomalyLogsCloud: [],
  anomalyTypes: [],
  originalTransportAnomalyList: [],
  transportAnomalyList: [],
  equipmentScheduleValidationList: [],
  originalEquipmentsSchedules: [],
  originalAnomalyTypes: [],
  originalAnomalyLogs: [],
  isEquipmentsSchedulesDirty: false,
  isAnomalyTypesDirty: false,
  isAnomalyLogsDirty: false,
  currentEquipmentSchedulesFilter: EQUIPMENT_SCHEDULES_INITIAL_FILTER,
  currentAnomalyLogsFilter: ANOMALY_LOGS_INITIAL_FILTER,
  equipmentScheduleStatuses: [],
  loadMaterialMappings: [],
  originalLoadMaterialMappings: [],
  isLoadMaterialMappingsDirty: false,
  currentLoadMaterialMappingsFilter: LOAD_MAPPINGS_INITIAL_FILTER,
  loadAllocations: [],
  truckAllocations: [],
  socket: null,
  selectedLoadAllocation: {
    id: 0,
    equip_id: 0,
    equip_type_id: 0,
    equip_group_id: 0,
    equip_name: '',
    priority: null,
    origin_element_id: 0,
    origin_element_point_id: 0,
    group_id: null,
    group_name: null,
    status: '',
    trucks: [],
    truck_groups: [],
    materials: [],
    dynamic_allocation: false,
    dynamic_configuration: {
      id: null,
      group_name: '',
      max_allocation_capacity: 0,
      max_load_queue_time: 0,
      mean_queue_time_range: 0,
      min_permanent_allocation_cycles: 0,
      max_temporary_allocation_cycles: 0,
    },
  },
  selectedTruckAllocation: {
    id: 0,
    equip_type_id: 0,
    equip_group_id: 0,
    equip_id: 0,
    equip_type_name: '',
    equip_group_name: '',
    equip_name: '',
    load_allocation_id: 0,
    status: '',
  },
  pendingTruckAllocationUpdate: null,
  truckOrigins: [],
  excavatorOrigins: [],
  truckDestinations: [],
  elementDestinations: [],
  selectedTruckOrigin: {
    id: null,
    equip_type_id: 0,
    equip_group_id: 0,
    equip_id: 0,
    equip_type_name: '',
    equip_group_name: '',
    equip_name: '',
    current_excavator_id: 0,
    current_excavator_name: '',
    operation_type: 0,
    load_allocation_id: null,
    load_allocation_excavator_name: null,
    velocity: 0,
    current_origin_point_id: 0,
    current_origin_point_name: '',
    current_destination_point_id: 0,
    current_destination_point_name: '',
    current_material_id: 0,
    current_material_name: '',
    status: 'IN',
    code_type_id: null,
    code_group_id: null,
    code_id: null,
    code_group_name: null,
    code_name: null,
    is_logout: null,
  },
  selectedLoadOrigin: {
    id: null,
    equip_type_id: 0,
    equip_group_id: 0,
    equip_id: 0,
    equip_type_name: '',
    equip_group_name: '',
    equip_name: '',
    priority: null,
    operation_type: 0,
    current_material_id: 0,
    current_material_name: null,
    current_origin_point_id: 0,
    current_origin_point_name: null,
    current_destination_point_id: 0,
    current_destination_point_name: '',
    operator_id: 0,
    operator_name: '',
    operator_group_id: 0,
    operator_group_name: '',
    status: 'IN',
    code_type_id: 0,
    code_group_id: 0,
    code_id: 0,
    code_group_name: '',
    code_name: '',
  },
  selectedTruckDestination: {
    id: null,
    equip_type_id: 0,
    equip_group_id: 0,
    equip_id: 0,
    equip_type_name: '',
    equip_group_name: '',
    equip_name: '',
    load_allocation_id: null,
    load_allocation_excavator_name: null,
    destination_point_id: null,
    destination_point_name: null,
    operation_type: 0,
    status: '',
    code_type_id: null,
    code_group_id: null,
    is_logout: null,
    current_destination_id: 0,
    current_destination_name: null,
    current_excavator_id: 0,
    current_excavator_name: '',
    velocity: 0,
    current_origin_point_id: 0,
    current_origin_point_name: '',
    current_destination_point_id: 0,
    current_destination_point_name: '',
    current_material_id: 0,
    current_material_name: '',
    code_id: null,
    code_group_name: '',
    code_name: '',
  },
  selectedElementDestination: {
    id: 0,
    id_element: 0,
    name: '',
    active: 1,
    active_name: 'Ativo',
    is_programmed: false,
  },
  autoAllocationLogs: '',
  operationalControl: [],
  isOperationalControlDirty: false,
  originalOperationalControl: [],
  currentOperationalFilter: DISPATCH_OPERATIONAL_CONTROL_FILTERS,
  truckIndicators: {
    list: [],
    indicators: [],
    parseTimestamp: null,
    frequencyTypes: [],
  },
  loaderIndicators: {
    list: [],
    indicators: [],
    parseTimestamp: null,
    frequencyTypes: [],
  },
  alerts: {
    alert_status: false,
  },
  equipmentScheduleSubelements: [],
  perforatorEquipmentScheduleList: [],
  selectedPerforatorAllocation: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_SYNC_STATUS_LIST: {
      const newData = action.payload;
      const newLoadOrigin = {
        ...state.selectedLoadOrigin,
        ...newData.find(item => item.equip_id === state.selectedLoadOrigin.equip_id),
      };
      const newTruckOrigin = {
        ...state.selectedTruckOrigin,
        ...newData.find(item => item.equip_id === state.selectedTruckOrigin.equip_id),
      };

      return {
        ...state,
        syncStatus: action.payload,
        selectedLoadOrigin: newLoadOrigin,
        selectedTruckOrigin: newTruckOrigin,
      };
    }
    case types.SET_EQUIPMENT_STATUS_LIST: {
      return {
        ...state,
        equipmentStatuses: action.payload,
      };
    }
    case types.SET_EQUIPMENT_SCHEDULE_SUBELEMENTS_LIST: {
      return {
        ...state,
        equipmentScheduleSubelements: action.payload,
      };
    }
    case types.SET_PERFORATOR_EQUIPMENT_SCHEDULE_LIST: {
      return {
        ...state,
        perforatorEquipmentScheduleList: action.payload,
      };
    }
    case types.SET_EQUIPMENT_STATUS_ITEM: {
      return {
        ...state,
        isCurrentEquipmentStatusUpdated: false,
        currentEquipmentStatus: action.payload,
      };
    }
    case types.SET_ORIGINAL_EQUIPMENT_STATUS_ITEM: {
      return {
        ...state,
        originalCurrentEquipmentStatus: action.payload,
      };
    }
    case types.RESTORE_EQUIPMENT_STATUS_ITEM: {
      return {
        ...state,
        isCurrentEquipmentStatusUpdated: false,
        currentEquipmentStatus: { ...state.originalCurrentEquipmentStatus },
      };
    }
    case types.SET_EQUIPMENT_STATUS_SAVED: {
      return {
        ...state,
        isCurrentEquipmentStatusUpdated: true,
      };
    }
    case types.RESTORE_EQUIPMENT_STATUS_FILTER: {
      return {
        ...state,
        currentEquipmentStatusFilter: { ...EQUIPMENT_STATUS_INITIAL_FILTER },
      };
    }
    case types.SET_EQUIPMENT_STATUS_FILTER: {
      const { key, value } = action.payload;
      const newData = {
        ...state.currentEquipmentStatusFilter,
        [key]: value,
      };
      return {
        ...state,
        currentEquipmentStatusFilter: newData,
      };
    }
    case types.SET_PREVENTIVE_MAINTENANCE_TYPES_LIST: {
      return {
        ...state,
        preventiveTypes: action.payload,
      };
    }
    case types.SET_TRANSPORT_ANOMALY_LIST: {
      return {
        ...state,
        originalTransportAnomalyList: action.payload,
        transportAnomalyList: action.payload,
        isTransportAnomalyListDirty: false,
      };
    }
    case types.SET_TRANSPORT_ANOMALY_LIST_VALUE: {
      const newItem = {
        ...action.payload,
      };
      const newData = remapData(state.transportAnomalyList, newItem, 'id');
      const isTransportAnomalyListDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTransportAnomalyListDirty,
        transportAnomalyList: newData,
      };
    }
    case types.SET_ANOMALY_TYPES_LIST: {
      return {
        ...state,
        isAnomalyTypesDirty: false,
        originalAnomalyTypes: action.payload,
        anomalyTypes: action.payload,
      };
    }
    case types.SET_EQUIPMENTS_SCHEDULES_LIST: {
      return {
        ...state,
        isEquipmentsSchedulesDirty: false,
        originalEquipmentsSchedules: action.payload,
        equipmentsSchedules: action.payload,
      };
    }
    case types.SET_ANOMALY_LOGS_LIST: {
      return {
        ...state,
        isAnomalyLogsDirty: false,
        originalAnomalyLogs: action.payload,
        anomalyLogs: action.payload,
      };
    }
    case types.SET_ANOMALY_LOGS_CODES_LIST: {
      return {
        ...state,
        anomalyLogsCodes: action.payload,
      };
    }
    case types.SET_ANOMALY_LOGS_CLOUD_LIST: {
      return {
        ...state,
        anomalyLogsCloud: action.payload,
      };
    }
    case types.SET_ALERTS_LIST: {
      return {
        ...state,
        alerts: action.payload,
      };
    }
    case types.RESET_ANOMALY_LOGS: {
      return {
        ...state,
        anomalyLogs: state.originalAnomalyLogs,
        isAnomalyLogsDirty: false,
      };
    }
    case types.RESET_TRANSPORT_ANOMALY_LIST: {
      return {
        ...state,
        transportAnomalyList: [...state.originalTransportAnomalyList],
        isTransportAnomalyListDirty: false,
      };
    }
    case types.SET_ANOMALY_LOGS_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.anomalyLogs, newItem, 'id');
      const isAnomalyLogsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isAnomalyLogsDirty,
        anomalyLogs: newData,
      };
    }
    case types.SET_EQUIPMENTS_SCHEDULES_VALIDATION_LIST: {
      return {
        ...state,
        equipmentScheduleValidationList: action.payload,
      };
    }
    case types.SET_EQUIPMENT_SCHEDULE_STATUSES_LIST: {
      return {
        ...state,
        equipmentScheduleStatuses: action.payload,
      };
    }
    case types.RESET_EQUIPMENTS_SCHEDULES: {
      return {
        ...state,
        equipmentsSchedules: state.originalEquipmentsSchedules,
        isEquipmentsSchedulesDirty: false,
      };
    }
    case types.SET_EQUIPMENTS_SCHEDULE_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.equipmentsSchedules, newItem, 'id');
      const isEquipmentsSchedulesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isEquipmentsSchedulesDirty,
        equipmentsSchedules: newData,
      };
    }
    case types.ADD_EQUIPMENTS_SCHEDULE: {
      const newItems = action.payload.map((r, i) => ({
        ...r,
        id: -(state.equipmentsSchedules.length + i + 1),
        update_status: 'I',
        active: 1,
        isDirty: true,
      }));
      const newData = [...newItems, ...state.equipmentsSchedules];
      const isEquipmentsSchedulesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isEquipmentsSchedulesDirty,
        equipmentsSchedules: newData,
      };
    }
    case types.ADD_DISPATCH_OPERATIONAL_CONTROL: {
      const newItem = { ...action.payload };
      const operationalControl = [newItem, ...state.operationalControl];
      const isOperationalControlDirty = operationalControl.some(d => d.update_status);
      return {
        ...state,
        isOperationalControlDirty,
        operationalControl,
      };
    }
    case types.RESTORE_DISPATCH_OPERATIONAL_CONTROL: {
      return {
        ...state,
        isOperationalControlDirty: false,
        operationalControl: state.originalOperationalControl,
      };
    }
    case types.SET_OPERATIONAL_CONTROL_VALUES: {
      const operationalControl = remapData(state.operationalControl, action.payload, 'id');
      const isOperationalControlDirty = operationalControl.some(d => d.update_status);
      return {
        ...state,
        isOperationalControlDirty,
        operationalControl,
      };
    }
    case types.RESTORE_EQUIPMENT_SCHEDULES_FILTER: {
      return {
        ...state,
        currentEquipmentSchedulesFilter: { ...EQUIPMENT_SCHEDULES_INITIAL_FILTER },
      };
    }
    case types.SET_EQUIPMENT_SCHEDULES_FILTER: {
      return {
        ...state,
        currentEquipmentSchedulesFilter: action.payload,
      };
    }
    case types.RESTORE_ANOMALY_LOGS_FILTER: {
      return {
        ...state,
        currentAnomalyLogsFilter: { ...ANOMALY_LOGS_INITIAL_FILTER },
      };
    }
    case types.SET_ANOMALY_LOGS_FILTER: {
      return {
        ...state,
        currentAnomalyLogsFilter: action.payload,
      };
    }

    case types.SET_LOAD_MATERIAL_MAPPINGS_LIST: {
      const materials = action.payload
        .filter(r => r.load_allocation_id
          && r.load_allocation_id === state.selectedLoadAllocation.id)
        .map(r => r.material_id);

      while (materials.length < 2) {
        materials.push(0);
      }

      const newSelectedLoadAllocation = {
        ...state.selectedLoadAllocation,
        materials,
      };

      return {
        ...state,
        selectedLoadAllocation: newSelectedLoadAllocation,
        isLoadMaterialMappingsDirty: false,
        originalLoadMaterialMappings: action.payload,
        loadMaterialMappings: action.payload,
      };
    }
    case types.SET_LOAD_MATERIAL_MAPPINGS_VALUE: {
      const item = action.payload;
      const currentItemIsProjection = item.id === null;
      const newItem = {
        ...action.payload,
        update_status: currentItemIsProjection ? 'I' : 'U',
      };

      const newData = remapDataByMultiKeys(
        state.loadMaterialMappings, newItem, ['equip_id', 'material_id'],
      );

      const isLoadMaterialMappingsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isLoadMaterialMappingsDirty,
        loadMaterialMappings: newData,
      };
    }
    case types.SET_LOAD_MATERIAL_MAPPINGS_ITEM: {
      const newItem = {
        ...action.payload,
      };
      return {
        ...state,
        loadMaterialMappings: [
          ...state.loadMaterialMappings,
          newItem,
        ],
        isLoadMaterialMappingsDirty: true,
      };
    }
    case types.RESET_LOAD_MATERIAL_MAPPINGS: {
      return {
        ...state,
        loadMaterialMappings: state.originalLoadMaterialMappings,
        isLoadMaterialMappingsDirty: false,
      };
    }
    case types.CLEAR_LOAD_MATERIAL_MAPPINGS_LIST: {
      return {
        ...state,
        loadMaterialMappings: [],
        isLoadMaterialMappingsDirty: false,
      };
    }
    case types.SET_LOAD_ALLOCATIONS_LIST: {
      return {
        ...state,
        loadAllocations: action.payload,
      };
    }
    case types.SET_TRUCK_ALLOCATIONS_LIST: {
      return {
        ...state,
        truckAllocations: action.payload,
      };
    }
    case types.SET_SELECTED_LOAD_ALLOCATION: {
      const baseAllocation = action.payload || INITIAL_STATE.selectedLoadAllocation;

      const foundTruckAllocations = state.truckAllocations
        .filter(r => r.load_allocation_id && r.load_allocation_id === baseAllocation.id);

      const trucks = foundTruckAllocations.map(r => r.equip_id);
      const truckGroups = foundTruckAllocations.map(r => r.equip_group_id);

      let dynamicConfiguration = baseAllocation.dynamic_configuration;
      if (!dynamicConfiguration) {
        dynamicConfiguration = {
          id: null,
          group_name: '',
          max_allocation_capacity: 0,
          max_load_queue_time: 0,
          mean_queue_time_range: 0,
          min_permanent_allocation_cycles: 0,
          max_temporary_allocation_cycles: 0,
        };
      }

      return {
        ...state,
        selectedLoadAllocation: {
          ...baseAllocation,
          truck_groups: truckGroups,
          dynamic_allocation: baseAllocation.dynamic_allocation || false,
          dynamic_configuration: dynamicConfiguration,
          trucks,
          origin_element_id: baseAllocation.origin_element_id || 0,
          origin_element_point_id: baseAllocation.origin_element_point_id || 0,
          materials: baseAllocation.materials || [0, 0],
        },
      };
    }
    case types.SET_SELECTED_TRUCK_ALLOCATION: {
      return {
        ...state,
        selectedTruckAllocation: action.payload,
      };
    }

    case types.SET_SELECTED_PERFORATOR_ALLOCATION: {
      return {
        ...state,
        selectedPerforatorAllocation: action.payload,
      };
    }

    case types.SET_PENDING_TRUCK_ALLOCATION_UPDATE: {
      return {
        ...state,
        pendingTruckAllocationUpdate: action.payload,
      };
    }
    case types.SET_SELECTED_LOAD_ALLOCATION_VALUES: {
      return {
        ...state,
        selectedLoadAllocation: action.payload,
      };
    }
    case types.SET_TRUCK_ALLOCATION: {
      const { truckAllocation, allocateTo } = action.payload;
      const newAllocation = state.truckAllocations
        .find(r => r.equip_id === truckAllocation.equip_id);
      newAllocation.load_allocation_id = allocateTo === 0 ? null : allocateTo;
      const newList = remapData(state.truckAllocations, newAllocation, 'equip_id');
      return {
        ...state,
        truckAllocations: newList,
      };
    }
    case types.SET_DISPATCH_OPERATION_CONTROL_LIST: {
      return {
        ...state,
        operationalControl: action.payload,
        originalOperationalControl: action.payload,
      };
    }
    case types.SET_CURRENT_OPERATIONAL_CONTROL_FILTER: {
      return {
        ...state,
        currentOperationalFilter: {
          ...action.payload,
        },
      };
    }
    case types.CLEAR_CURRENT_OPERATIONAL_CONTROL_FILTER: {
      return {
        ...state,
        currentOperationalFilter: {
          ...DISPATCH_OPERATIONAL_CONTROL_FILTERS,
        },
      };
    }
    case types.SET_DISPATCH_ORIGINS_TRUCKS: {
      return {
        ...state,
        truckOrigins: action.payload,
      };
    }
    case types.SET_DISPATCH_ORIGINS_EXCAVATORS: {
      return {
        ...state,
        excavatorOrigins: action.payload,
      };
    }
    case types.SET_DISPATCH_DESTINATION_TRUCKS: {
      return {
        ...state,
        truckDestinations: action.payload,
      };
    }
    case types.SET_DISPATCH_DESTINATION_ELEMENTS: {
      return {
        ...state,
        elementDestinations: action.payload,
      };
    }
    case types.SET_SELECTED_TRUCK_ORIGIN: {
      return {
        ...state,
        selectedTruckOrigin: action.payload || INITIAL_STATE.selectedTruckOrigin,
      };
    }
    case types.SET_SELECTED_LOAD_ORIGIN: {
      return {
        ...state,
        selectedLoadOrigin: action.payload || INITIAL_STATE.selectedLoadOrigin,
      };
    }
    case types.SET_SELECTED_TRUCK_DESTINATION: {
      return {
        ...state,
        selectedTruckDestination: action.payload || INITIAL_STATE.selectedTruckDestination,
      };
    }
    case types.SET_SELECTED_ELEMENT_DESTINATION: {
      return {
        ...state,
        selectedElementDestination: action.payload || INITIAL_STATE.selectedElementDestination,
      };
    }
    case types.SET_AUTO_ALLOCATION_LOGS: {
      return {
        ...state,
        autoAllocationLogs: JSON.stringify(action.payload),
      };
    }
    case types.SET_DISPATCH_TRUCK_INDICATORS_LIST: {
      return {
        ...state,
        truckIndicators: {
          list: [...action.payload],
          parseTimestamp: action.parse_timestamp,
          indicators: action.indicators,
          frequencyTypes: action.frequency_types,
        },
      };
    }
    case types.SET_DISPATCH_LOADER_INDICATORS_LIST: {
      return {
        ...state,
        loaderIndicators: {
          list: [...action.payload],
          parseTimestamp: action.parse_timestamp,
          indicators: action.indicators,
          frequencyTypes: action.frequency_types,
        },
      };
    }
    case types.SET_DISPATCH_SOCKET_CONNECTION: {
      return {
        ...state,
        socket: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
