import React from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { useTranslation } from 'react-i18next';
import { isAuthenticated } from './services/authentication';
import Login from './pages/Login';
import Home from './pages/Home';
import Manager from './pages/Manager';
import Fuel from './pages/Fuel';
import Contracts from './pages/Contracts';
import Search from './pages/Search';
import DailyParts from './pages/DailyParts';
import Underground from './pages/Underground';
import Maintenance from './pages/Maintenance';
import Dispatch from './pages/Dispatch';
import Quality from './pages/Quality';
import Telemetry from './pages/Telemetry';
import Configuration from './pages/Configuration';
import Lineup from './pages/Lineup';
import Admin from './pages/Admin';
import Password from './pages/PasswordRedefiniton';
import SocketioConnection from '~/components/SocketioConnection';
import Dynamic from './pages/Dynamic';

const ZENDESK_KEY = '85b7c368-1bff-42ce-8a4e-8133c612da1c';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (isAuthenticated() ? (
      <>
        <SocketioConnection />
        <Component {...props} />
      </>

    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    ))}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

PrivateRoute.defaultProps = {
  location: {},
};

const Routes = () => {
  const { t: translate } = useTranslation();

  const renderZendeskWidget = () => (
    <Zendesk
      defer
      zendeskKey={ZENDESK_KEY}
      onRender={() => {
        ZendeskAPI('webWidget', 'hide');
      }}
    />
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/manager" component={Manager} />
        <PrivateRoute path="/fuel" component={Fuel} />
        <PrivateRoute path="/contracts" component={Contracts} />
        <PrivateRoute path="/underground" component={Underground} />
        <PrivateRoute path="/maintenance" component={Maintenance} />
        <PrivateRoute path="/dispatch" component={Dispatch} />
        <PrivateRoute path="/quality" component={Quality} />
        <PrivateRoute path="/telemetry" component={Telemetry} />
        <PrivateRoute path="/configurations" component={Configuration} />
        <PrivateRoute exact path="/parts" component={Search} />
        <PrivateRoute path="/parts/list" component={DailyParts} />
        <PrivateRoute path="/lineup" component={Lineup} />
        <PrivateRoute path="/admin" component={Admin} />
        <PrivateRoute path="/dynamic" component={Dynamic} />
        <PrivateRoute exact path="/passwordRedefinition" component={Password} />
        <Route path="*" component={() => <h1>{translate('common:PageNotFound')}</h1>} />
      </Switch>
      {renderZendeskWidget()}
    </BrowserRouter>
  );
};

export default Routes;
