import React, { useState, useEffect } from 'react';
import { ZendeskAPI } from 'react-zendesk';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MTabs from '~/components/PF2MTabs';
import Main from '../Main';
import { setAlertDialog } from '~/store/manager/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import CodeMigrationTab from './CodeMigrationTab';

const Dynamic = ({
  classes,
  setAlertDialog: setDialog,
  alertDialog,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:CodeMigrations') },
  ];

  const handleChange = (event, value) => {
    const blockChange = true;

    if (blockChange) {
      event.preventDefault();
      setDialog();
    } else {
      setSelectedTab(value);
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Main>
        <div className={classes.container}>
          <PF2MTabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs}
          </PF2MTabs>
          {selectedTab === 0 ? (<CodeMigrationTab />) : null}
        </div>
        <PF2MAlertDialog
          hasCancel={false}
          confirmText={translate('common:Understood')}
          description={translate('validation:YouHaveUnsavedData')}
          open={alertDialog}
          onConfirm={() => setDialog()}
        />
      </Main>
    </>
  );
};

Dynamic.propTypes = {
  classes: PropTypes.object.isRequired,
  setAlertDialog: PropTypes.func,
  alertDialog: PropTypes.bool,
};

Dynamic.defaultProps = {
  setAlertDialog: null,
  alertDialog: false,
};

const mapStateToProps = state => ({
  isCodesEquipmentLinkMapsDirty: state.fuel.isCodesEquipmentLinkMapsDirty,
  isFuelRemainingAlertValuesDirty: state.fuel.isFuelRemainingAlertValuesDirty,
  alertDialog: state.manager.alertDialog,
});

const mapDispatchToProps = {
  setAlertDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Dynamic));
