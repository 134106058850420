import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getEquipmentTypesTabs } from '~/store/dailyparts/actions';
import PF2MLoading from '~/components/PF2MLoading';
import { ZendeskAPI } from 'react-zendesk';
import Main from '../Main';
import SearchHeader from './SearchHeader';
import PF2MTabs from '~/components/PF2MTabsWithAppBar';
import TransportList from './TransportList';
import HourmeterList from './HourmeterList';
import CodeList from './CodeList';
import CodeRawList from './CodeRawList';
import HourMachineList from './HourMachineList';
import LoadList from './LoadList';
import PerforatorList from './PerforatorList';
import RefuelerList from './RefuelerList';
import TractorList from './TractorList';
import InfrastructureList from './InfrastructureList';
import ShaftList from './ShaftList';
import FillinList from './FillinList';
import ScissorLiftList from './ScissorLiftList';
import {
  getCodeTypes,
  getCodeGroups,
  getCodes,
  getSubElements,
  getExceptionTypes,
  getSleepLevels,
  getDrillholeTypes,
  getFireIds,
  getFandrillFans,
  getFandrillBitDiameters,
  getFandrillBitDiametersNames,
  getHourmeterNames,
  getDrillholeDepths,
  getTeams,
  getOperationTypes,
  getOperationTypeActivities,
  getTeamGroups,
  getTurnsGroups,
  getTransportOperationTypes,
  getLoads,
  getControlTypes,
  getBoomReasons,
} from '~/store/manager/actions';
import styles from './styles';
import RailRoadList from './RailRoadList';
import ActivityList from './ActivityList';
import CrusherList from './CrusherList';

const TABS_COMPONENTS = {
  transport_report: <TransportList />,
  load_report: <LoadList />,
  perforator_report: <PerforatorList />,
  refueler_report: <RefuelerList />,
  code_report: <CodeList />,
  codes_raw_report: <CodeRawList />,
  hourmeter_report: <HourmeterList />,
  tractor_report: <TractorList />,
  infrastructure_report: <InfrastructureList />,
  shaft_report: <ShaftList />,
  hour_machine_report: <HourMachineList />,
  fillin_report: <FillinList />,
  scissor_lift_report: <ScissorLiftList />,
  railroad_report: <RailRoadList />,
  hour_machine_picture_report: <ActivityList />,
  crusher_report: <CrusherList />,
};

const DailyParts = ({ history }) => {
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const filters = useSelector(state => state.dailyParts.filters);
  const equipTab = useSelector(state => state.dailyParts.equipmentTabs
    .find(e => e.id_equipment_type === filters.equipmentType));
  const isSubmittingData = useSelector(state => state.dailyParts.isSubmittingData);
  const userConfig = useSelector(state => state.auth.user.config);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dispatch(getEquipmentTypesTabs()),
      dispatch(getCodeTypes(true)),
      dispatch(getTurnsGroups(true)),
      dispatch(getTeamGroups(true)),
      dispatch(getCodeGroups(true)),
      dispatch(getCodes(true)),
      dispatch(getControlTypes(true)),
      dispatch(getSubElements(true)),
      dispatch(getExceptionTypes()),
      dispatch(getSleepLevels(true)),
      dispatch(getDrillholeTypes(true)),
      dispatch(getHourmeterNames(true)),
      dispatch(getFireIds(true)),
      dispatch(getFandrillFans()),
      dispatch(getFandrillBitDiameters()),
      dispatch(getFandrillBitDiametersNames()),
      dispatch(getDrillholeDepths()),
      dispatch(getOperationTypes()),
      dispatch(getOperationTypeActivities()),
      dispatch(getTeams()),
      dispatch(getTransportOperationTypes(true)),
      dispatch(getBoomReasons()),
    ]).then(() => setIsLoading(false));
  }, [filters, dispatch]);

  useEffect(() => {
    function loadData() {
      dispatch(getLoads(filters.equipmentType));
    }

    if (filters.equipmentType) {
      loadData();
    }
  }, [filters.equipmentType, dispatch]);

  const filterTabs = (tabs) => {
    const codeTab = tabs.find(e => e.name === 'code_report');
    const hourmeterTab = tabs.find(e => e.name === 'hourmeter_report');
    const hourmachineTab = tabs.find(e => e.name === 'hour_machine_report');
    const hourmachinePictureTab = tabs.find(e => e.name === 'hour_machine_picture_report');
    const codesRawTab = tabs.find(e => e.name === 'codes_raw_report');
    const customTab = tabs.find(e => ![
      'hourmeter_report',
      'code_report',
      'codes_raw_report',
      'hour_machine_report',
      'hour_machine_picture_report',
    ].includes(e.name));
    const codeToogle = userConfig?.find(e => e.key === 'codes_raw_daily_parts_replace');
    const hourMachineToogle = userConfig
      ?.find(e => e.key === 'hourmachine_picture_daily_parts_replace');

    const newTabs = [];
    if (customTab) {
      newTabs.push(customTab);
    }
    if (codeTab && codeToogle?.value === '0') {
      newTabs.push(codeTab);
    } else if (codeTab && codeToogle?.value === '1') {
      newTabs.push(codesRawTab);
    }
    if (hourmeterTab) {
      newTabs.push(hourmeterTab);
    }
    if (hourmachineTab && hourMachineToogle?.value === '0') {
      newTabs.push(hourmachineTab);
    }
    if (hourmachinePictureTab && hourMachineToogle?.value === '1') {
      newTabs.push(hourmachinePictureTab);
    }

    return newTabs.map((t, i) => ({
      ...t,
      key: i,
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Main hasLoading={false}>
      {
        (isLoading)
          ? <PF2MLoading loading />
          : (
            <>
              <SearchHeader history={history} />
              {equipTab && !isSubmittingData
                ? (
                  <PF2MTabs tabs={filterTabs(equipTab.tabs).map((t, i) => ({
                    label: translate(`common:${t.description}`),
                    component: TABS_COMPONENTS[t.name],
                    key: i,
                  }))}
                  />
                ) : null}
            </>
          )
      }
    </Main>
  );
};

DailyParts.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(DailyParts);
