import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformConfiguration } from '~/store/manager/actions';

export const usePlatformConfigurationAuth = (key) => {
  if (!key) {
    throw new Error('Missing key to get platform_configuration');
  }
  const configuration = useSelector((state) => {
    const config = state.auth.user.config
      ? state.auth.user.config.find(conf => conf.key === key)
      : null;
    return config || { error: 'Not Found', key };
  });
  return configuration;
};

export default function usePlatformConfiguration(key) {
  if (!key) {
    throw new Error('Missing key to get platform_configuration');
  }

  const configuration = useSelector((state) => {
    const config = state.manager.platformConfiguration.find(conf => conf.key === key);
    return config || { error: 'Not Found', key };
  });
  return configuration;
}

export function usePlatformConfigurationUserAccessLevel(accessLevel) {
  if (!accessLevel) {
    throw new Error('Missing key to get platform_configuration');
  }

  const accessLevelMapperLockEditKeys = {
    1: 'lock_edit_date_admin',
    2: 'lock_edit_date_adminlocal',
    3: 'lock_edit_date_normaluser',
  };

  const configuration = useSelector((state) => {
    const config = state.manager.platformConfiguration.find(
      conf => conf.key === accessLevelMapperLockEditKeys[accessLevel],
    );
    return config || { error: 'Access level not found', accessLevel };
  });
  return configuration;
}

export function useFeatureFlagPlatformConfiguration(key, defaultValue = false) {
  // required key avoid use it dinamically
  if (!key) {
    throw new Error('Missing key to get platform_configuration');
  }

  const dispatch = useDispatch();
  useEffect(() => {
    // confirm the platformConfiguration has been fetched
    async function fetchData() {
      dispatch(getPlatformConfiguration());
    }
    fetchData();
  }, [dispatch]);

  // Search the configuration if not valid use default value
  const configuration = useSelector((state) => {
    const config = state.manager.platformConfiguration.find(conf => conf.key === key);
    if (config) return config?.value === '1';
    return defaultValue;
  });
  return configuration;
}
