import React, { useState } from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PF2MDataTable from '~/components/PF2MDataTable';
import Photo from '@material-ui/icons/Photo';
import {
  newActivityList,
  setActivityValues,
  deleteActivityItem,
  restoreOriginalActivity,
  updateActivityList,
  uploadActivityPicture,
} from '~/store/dailyparts/actions';
import styles from '../styles';
import PictureModal from './PictureModal';
import { usePlatformConfigurationUserAccessLevel } from '~/services/platformConfiguration';
import { subtractDaysFromNow, formatTimestamp, convertTimeStampToMoment } from '~/utils/moment';

const formatActivityList = row => ({
  ...row,
  timestamp_op: row.timestamp_op * 1000,
  hour: Number(row.hour).toFixed(2),
});

const unFormatActivityList = row => ({
  ...row,
  timestamp_op: row.timestamp_op / 1000,
  hour: Number(row.hour),
  code_id: row.code_id === '0' ? null : row.code_id,
  op_group: row.op_group === '0' ? null : row.op_group,
  op: row.op === '0' ? null : row.op,
  area_id: row.area_id === '0' ? null : row.area_id,
  subarea_id: row.subarea_id === '0' ? null : row.subarea_id,
});

const ActivityList = () => {
  const { t: translate } = useTranslation();
  const activityList = useSelector(state => state.dailyParts.activityList.map(formatActivityList));
  const filters = useSelector(state => state.dailyParts.filters);
  const userAccessLevel = useSelector(state => state.auth.user.level);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(eg => eg.id_equipament === filters.equipmentType), shallowEqual);
  const isActivityListDirty = useSelector(st => st.dailyParts.isActivityListDirty);
  const codeTypes = useSelector(state => state.manager.codeTypes);
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const equipments = useSelector(state => state.manager.equipments
    .filter(equip => equip.id_equip === filters.equipmentType), shallowEqual);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups);
  const operators = useSelector(state => state.manager.operators);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const [pictureModalData, setPictureModalData] = useState(null);
  const dispatch = useDispatch();

  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);

  const validateDates = type => (row) => {
    const userLimitEdit = subtractDaysFromNow(userLimitEditConfig);

    const momentDate = convertTimeStampToMoment(formatTimestamp(row[type]));
    if (type === 'timestamp_op' && momentDate.isBefore(userLimitEdit)) {
      return { isValid: false, helperText: translate('validation:InvalidPeriodDate') };
    }

    return true;
  };

  const columns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'timestamp_op',
      customType: 'dateTimeByTimestamp',
      validate: validateDates('timestamp_op'),
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'eq_group',
      lookup: equipmentGroups,
      validate: row => Boolean(row.eq_group),
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      customType: 'filterLookup',
      field: 'eq_id',
      lookup: equipments,
      lookupFilter: (opt, row) => String(opt.id_group) === String(row.eq_group)
          || String(row.eq_group) === '0',
      validate: row => Boolean(row.eq_id) && String(row.eq_id) !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Hourmeter'),
      field: 'hour',
      type: 'numeric',
    },
    {
      title: translate('common:Picture'),
      editable: false, // avoid problems with default editable
      field: 'picture_name',
      render: row => (
        <IconButton
          style={{ outline: 'none', color: row.picture_name ? 'rgb(242 170 0)' : 'red' }}
          onClick={() => {
            setPictureModalData(row);
          }}
        >
          <Photo />
        </IconButton>
      ),
    },
    {
      editable: 'always',
      title: translate('common:DistanceKm'),
      field: 'kilo',
      type: 'numeric',
    },
    {
      title: translate('common:Condition'),
      field: 'is_start',
      lookup: [
        { id: 0, name: translate('common:End') },
        { id: 1, name: translate('common:Start') },
      ],
    },
    {
      editable: 'always',
      title: translate('common:CodeType'),
      field: 'code_type_id',
      lookup: codeTypes.map(cType => ({
        id: cType.id, name: translate(`common:${cType.name}`),
      })),
    },
    {
      title: translate('common:CodeGroup'),
      field: 'code_group_id',
      customType: 'filterLookup',
      lookup: codeGroups,
      lookupFilter: (codeGroupOption, row) => (
        String(codeGroupOption.code_type) === String(row.code_type_id)
        && String(row.eq_type) === String(codeGroupOption.id_equip)
      ),
    },
    {
      title: translate('common:Code'),
      field: 'code_id',
      customType: 'filterLookup',
      lookup: codes,
      lookupKey: 'id_pk',
      lookupFilter: (codeOption, row) => {
        // No code Group Selected
        if (String(row.code_group_id) === '0') {
          return (
            String(row.code_type_id) === String(codeOption.code_type)
            && String(row.eq_type) === String(codeOption.id_equip)
          );
        }
        return (
          String(codeOption.id_group) === String(row.code_group_id)
          && String(row.eq_type) === String(codeOption.id_equip)
        );
      },
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'op_group',
      lookup: operatorsGroups,
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'op',
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookup: operators,
      lookupFilter: (opt, row) => String(opt.id_group) === String(row.op_group)
        || String(row.op_group) === '0',
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      customType: 'filterLookup',
      field: 'area_id',
      lookup: elements,
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      customType: 'filterLookup',
      field: 'subarea_id',
      lookup: subElements,
      lookupFilter: (opt, row) => String(opt.id_element) === String(row.area_id),
    },
    { title: translate('common:Latitude'), field: 'latitude', type: 'numeric' },
    { title: translate('common:Longitude'), field: 'longitude', type: 'numeric' },
    { title: translate('common:Altitude'), field: 'altitude', type: 'numeric' },
  ];

  const uploadImage = (act) => {
    const activity = unFormatActivityList(act);
    dispatch(
      uploadActivityPicture(
        activity,
      ),
    );
  };

  return (
    <>
      <PictureModal
        modalData={pictureModalData}
        closeModal={() => setPictureModalData(null)}
        uploadImage={uploadImage}
      />
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          hideCommit: !isActivityListDirty,
          hideReload: !isActivityListDirty,
          cacheKey: 'daily_parts_activity',
        }}
        onAdd={newData => dispatch(newActivityList(newData))}
        onDiscard={() => dispatch(restoreOriginalActivity())}
        onApply={() => dispatch(
          updateActivityList(activityList.map(unFormatActivityList), filters),
        )}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setActivityValues(unFormatActivityList(row)));
          });
        }}
        onDelete={(newData) => {
          newData.forEach(({ tableData, ...activityItem }) => {
            dispatch(deleteActivityItem(unFormatActivityList(activityItem)));
          });
        }}
        initialFormData={
          unFormatActivityList({
            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: null }), {}),
            eq_type: filters.equipmentType,
            picture_name: '',
            timestamp_op: new Date().getTime(),
            is_start: 0,
            hour: 0,
            kilo: 0,
            latitude: 0,
            longitude: 0,
            altitude: 0,
          })
        }
        columns={columns}
        data={activityList.length > 0 ? activityList : []}
      />
    </>
  );
};

ActivityList.propTypes = {};
ActivityList.defaultProps = {};

export default withStyles(styles)(ActivityList);
