const styles = {
  grid: {
    justifyContent: 'center',
  },
  fieldsContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelSpan: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#455873',
    display: 'block',
  },
  dailyPartsSelection: {
    marginTop: 20,
    textAlign: 'center',
  },
  unSelectedButton: {
    backgroundColor: '#455873',
    color: '#fff',
  },
};

export default styles;
