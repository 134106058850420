import React, { useState } from 'react';
import {
  IconButton,
  Modal,
  withStyles,
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  setTransportValues,
  deleteTransportItem,
  updateTransportList,
  restoreOriginalTransport,
  createTransportItem,
} from '~/store/dailyparts/actions';
import { useTranslation } from 'react-i18next';
import { AccessTime } from '@material-ui/icons';
import styles from '../styles';
import PF2MDataTable from '~/components/PF2MDataTable';
import TransportCycleTimesModal from './TransportCycleTimesModal';
import RenderDistanceManagerField from './RenderDistanceManagerField';
import usePlatformConfiguration, { usePlatformConfigurationUserAccessLevel } from '~/services/platformConfiguration';
import { subtractDaysFromNow, parseDate } from '~/utils/moment';


const ESCAVATOR_ID = 1;
const LOADER_ID = 15;
const ROCK_FILL_ID = 29;

const TransportList = () => {
  const { t: translate } = useTranslation();

  const filters = useSelector(state => state.dailyParts.filters);
  const userAccessLevel = useSelector(state => state.auth.user.level);

  const transportList = useSelector(state => state.dailyParts.transportList);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);

  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);

  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const materials = useSelector(state => state.manager.materials);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const sleepLevels = useSelector(state => state.manager.sleepLevels);
  const dispatch = useDispatch();
  const transportOperationTypes = useSelector(state => state.manager.transportOperationTypes);

  const [selectedTransportReport, setSelectedTransportReport] = useState();
  const [timeModalOpen, setTimeModalOpen] = useState(false);

  const { value: editLoadDefaultValue } = usePlatformConfiguration('edit_load_default');
  const editLoadDefault = parseInt(editLoadDefaultValue) === 1 ? 'always' : 'never';

  const loadDefault = useSelector(state => state.manager.loads);

  const filterLoadDefault = (data, idMaterial, idTruck) => data.find(ld => (
    ld.id_material === idMaterial && ld.id_equip === idTruck
  ));

  const checkLoadBalance = (data) => {
    if (parseInt(editLoadDefaultValue) === 1) return data;
    const oldData = data;
    if (oldData.id_group !== 0
        && oldData.id_truck !== 0
        && oldData.id_material_truck !== 0) {
      const LoadBalanceValue = filterLoadDefault(loadDefault,
        oldData.id_material_truck,
        oldData.id_truck);
      oldData.load_manager = LoadBalanceValue?.value || 0;
    }
    return oldData;
  };

  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);

  const validateDateOrTimeField = key => (rowData) => {
    const userLimitEdit = subtractDaysFromNow(userLimitEditConfig);

    const foundField = rowData[key];
    const invalidMsg = {
      isValid: false, helperText: translate('validation:RequiredField'),
    };

    const invalidEditPeriodDateMsg = {
      isValid: false, helperText: translate('validation:InvalidPeriodDate'),
    };

    const defaultValidation = !foundField || foundField.includes('Invalid');
    const validateTime = defaultValidation || foundField.includes('_');
    const validateDate = defaultValidation || foundField.length === 0;

    if (key.includes('time') && validateTime) return invalidMsg;
    if (key.includes('date') && validateDate) return invalidMsg;
    if (key === 'start_date' && parseDate(rowData[key]).isBefore(userLimitEdit)) return invalidEditPeriodDateMsg;

    return { isValid: true };
  };

  const validateLookupField = key => (rowData) => {
    if (['', '0', 0].includes(rowData[key])) {
      return { isValid: false, helperText: translate('validation: RequiredField') };
    }

    return { isValid: true };
  };

  // 1. Para a definição de campos obrigatórios com mensagem de erro,
  //    a chave "required" é necessária.
  // 2. Se o campo for de busca e estiver com o "lookup" e/ou "lookupFilter"
  //    se torna necessário a chave "customType" sendo "filterLookup".
  const columns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      customType: 'date',
      required: true,
      validate: validateDateOrTimeField('start_date'),
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
      customType: 'date',
      required: true,
      validate: validateDateOrTimeField('end_date'),
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
      customType: 'time',
      required: true,
      validate: validateDateOrTimeField('start_time'),
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
      customType: 'time',
      required: true,
      validate: validateDateOrTimeField('end_time'),
    },
    {
      editable: 'always',
      title: translate('common:EquipmentType'),
      field: 'id_equip',
      lookup: equipmentTypes,
      customType: 'filterLookup',
      required: true,
      validate: validateLookupField('id_equip'),
    },
    {
      editable: 'always',
      customType: 'filterLookup',
      title: translate('common:EquipmentGroup'),
      field: 'id_group',
      validate: validateLookupField('id_group'),
      required: true,
      lookup: equipmentsGroups,
      lookupFilter: (e, f) => String(e.id_equipament) === String(f.id_equip)
        || String(f.id_equip) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      customType: 'filterLookup',
      validate: validateLookupField('id_truck'),
      field: 'id_truck',
      required: true,
      lookup: equipments,
      lookupFilter: (e, f) => String(e.id_group) === String(f.id_group)
        || String(f.id_group) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      customType: 'filterLookup',
      validate: validateLookupField('id_mine'),
      required: true,
      field: 'id_mine',
      lookup: elements,
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      field: 'id_mine_origin',
      required: true,
      customType: 'filterLookup',
      validate: validateLookupField('id_mine_origin'),
      lookup: subElements,
      lookupFilter: (e, f) => String(e.id_element) === String(f.id_mine)
        || String(f.id_mine) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Destination'),
      customType: 'filterLookup',
      validate: validateLookupField('id_destination'),
      field: 'id_destination',
      required: true,
      lookup: elements,
    },
    {
      editable: 'always',
      title: translate('common:DestinationPoint'),
      customType: 'filterLookup',
      validate: validateLookupField('id_destination_point'),
      field: 'id_destination_point',
      required: true,
      lookup: subElements,
      lookupFilter: (option, row) => String(option.id_element) === String(row.id_destination)
        || String(row.id_destination) === '0',
    },
    {
      editable: 'never',
      title: translate('common:CycleStageTimes'),
      field: 'cycle_stage_times',
      render: row => (
        <IconButton
          style={{ outline: 'none' }}
          onClick={() => {
            setSelectedTransportReport(row);
            setTimeModalOpen(true);
          }}
        >
          <AccessTime style={{ color: 'rgb(242 170 0)' }} />
        </IconButton>
      ),
    },
    {
      editable: 'always',
      title: translate('common:Material'),
      customType: 'filterLookup',
      validate: validateLookupField('id_material_truck'),
      required: true,
      field: 'id_material_truck',
      lookup: materials,
    },
    {
      editable: 'always',
      title: translate('common:CycleTime'),
      field: 'duration',
    },
    {
      editable: 'always',
      title: translate('common:LoadTime'),
      field: 'load_time',
    },
    {
      editable: 'always',
      title: translate('common:UnloadTime'),
      field: 'unload_time',
    },
    {
      editable: 'always',
      title: translate('common:LoadQueueTime'),
      field: 'load_queue_time',
    },
    {
      editable: 'always',
      title: translate('common:UnloadQueueTime'),
      field: 'unload_queue_time',
    },
    {
      editable: 'always',
      title: translate('common:LoadManeuverTime'),
      field: 'load_maneuver_time',
    },
    {
      editable: 'always',
      title: translate('common:UnloadManeuverTime'),
      field: 'unload_maneuver_time',
    },
    {
      editable: 'always',
      title: translate('common:EmptyTrafficTime'),
      field: 'empty_time',
    },
    {
      editable: 'always',
      title: translate('common:FullTrafficTime'),
      field: 'full_time',
    },
    {
      editable: 'always',
      title: translate('common:EmptyTrafficDistance'),
      field: 'empty_distance',
    },
    {
      editable: 'always',
      title: translate('common:FullTrafficDistance'),
      field: 'full_distance',
    },
    {
      editable: 'always',
      title: translate('common:MaintenanceStopTime'),
      field: 'code_time',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'id_operator_truck_group',
      lookup: operatorsGroups,
      required: true,
      customType: 'filterLookup',
      validate: validateLookupField('id_operator_truck_group'),
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      customType: 'filterLookup',
      required: true,
      field: 'id_operator_truck',
      lookup: operators,
      lookupFilter: (e, f) => String(e.id_group) === String(f.id_operator_truck_group)
        || String(f.id_operator_truck_group) === '0',
      lookupKey: 'id_operator',
      validate: validateLookupField('id_operator_truck'),
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'turn',
      lookup: turns,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      required: true,
      lookup: teams,
      customType: 'filterLookup',
      validate: validateLookupField('team_id'),
    },
    {
      editable: editLoadDefault,
      title: translate('common:LoadDefault'),
      field: 'load_manager',
      lookup: editLoadDefaultValue === 0 ? loadDefault : null,
      customType: editLoadDefaultValue === 0 ? 'filterLookup' : null,
      lookupKey: 'name',
    },
    {
      editable: 'always',
      title: translate('common:LoadBalance'),
      field: 'load_balance',
    },
    {
      editable: 'always',
      title: translate('common:LoadEquipment'),
      required: true,
      customType: 'filterLookup',
      lookup: equipments.filter(equip => (
        [ESCAVATOR_ID, LOADER_ID, ROCK_FILL_ID].includes(equip.id_equip)
      )),
      field: 'id_load_equip',
      validate: validateLookupField('id_load_equip'),
    },
    {
      editable: 'never',
      title: translate('common:CycleType'),
      field: 'exception_type',
      lookup: exceptionTypes,
    },
    {
      editable: 'never',
      title: translate('common:DMT'),
      field: 'distance_manager',
    },
    {
      editable: 'always',
      title: translate('common:SleepLevel'),
      field: 'sleep_level',
      lookup: sleepLevels,
    },
    {
      editable: 'never',
      title: translate('common:StepsPerformedByAutomation'),
      field: 'automation_toggle_is_disable',
      render: row => <RenderDistanceManagerField transportReport={row} />,
    },
    {
      editable: 'always',
      title: translate('common:OperatorLoad'),
      field: 'id_operator_load',
      lookup: operators,
      customType: 'filterLookup',
      lookupKey: 'id_operator',
    },
    {
      editable: 'always',
      customType: 'filterLookup',
      title: translate('common:OperationType'),
      field: 'transport_operation_type',
      lookup: transportOperationTypes,
    },
  ];

  const setChangedTransportItem = (newTransportItem) => {
    setSelectedTransportReport({ ...selectedTransportReport, ...newTransportItem });
  };

  // campos obrigatórios não devem ter valor inicial.
  const initialFormData = {
    ...columns.reduce((acc, e) => {
      if (e.required) return { ...acc, [e.field]: '' };
      return { ...acc, [e.field]: '0' };
    }, {}),
    unloaded_stop_time: 0,
    loaded_stop_time: 0,
    is_special: 0,
    id_mine_manager: 0,
    id_mine_origin_manager: 0,
  };

  // só deve conseguir salvar todas as alterações caso todos os campos
  // obrigatórios das linhas alteradas sejam preenchidos.
  const disableSaveAllChangesButton = transportList?.length ? transportList
    .filter(transport => transport.touched)
    .flatMap(transport => (
      Object.entries(transport).map(([k, v]) => (
        columns.find(col => col.field === k)?.required && v === initialFormData[k]
      ))
    ))
    .includes(true) : false;

  return (
    <>
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          cacheKey: 'daily_parts_transport',
          disableSaveButton: disableSaveAllChangesButton,
        }}
        onAdd={newData => dispatch(createTransportItem(newData))}
        onDiscard={() => dispatch(restoreOriginalTransport())}
        onApply={() => {
          const removeTouchedField = transportList.map(({ touched, ...t }) => t);
          dispatch(updateTransportList(removeTouchedField, filters));
        }}
        onChange={(newData) => {
          newData.forEach(({ ...row }) => {
            const newRow = checkLoadBalance(row);
            dispatch(setTransportValues({ ...newRow, touched: true }));
          });
        }}
        onDelete={(newData) => {
          newData.forEach((transport) => {
            dispatch(deleteTransportItem(transport));
          });
        }}
        initialFormData={initialFormData}
        columns={columns}
        data={transportList.length > 0 ? transportList : []}
      />
      <Modal
        open={timeModalOpen}
        onClose={() => setTimeModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TransportCycleTimesModal
          handleClose={() => setTimeModalOpen(false)}
          onChangeSelectedTransportReport={setChangedTransportItem}
          selectedTransportReport={selectedTransportReport}
        />
      </Modal>
    </>
  );
};

export default withStyles(styles)(TransportList);
