import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PF2MDataTable from '~/components/PF2MDataTable';
import usePlatformConfiguration, { usePlatformConfigurationUserAccessLevel } from '~/services/platformConfiguration';
import {
  formatDate, formatTime, parseDateToTimestamp,
  newMomentDate, daysToMiliseconds, subtractDaysFromNow,
  parseDate,
} from '~/utils/moment';
import { getCodeTypes } from '~/store/manager/actions';
import {
  deleteCodeItem, newCodeItem, restoreOriginalCode, setCodeValues, updateCodeList,
} from '~/store/dailyparts/actions';
import styles from '../styles';

const CodeList = () => {
  const { t: translate } = useTranslation();
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const filters = useSelector(state => state.dailyParts.filters);
  const userAccessLevel = useSelector(state => state.auth.user.level);
  const codeList = useSelector(state => state.dailyParts.codeList);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const codeTypes = useSelector(state => state.manager.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })), shallowEqual);
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipments = useSelector(state => state.manager.equipments);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups);
  const operators = useSelector(state => state.manager.operators);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const dispatch = useDispatch();
  const { value: consolidationPeriod } = usePlatformConfiguration('expire_edition_data');
  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);

  const validateDates = type => (row) => {
    const now = new Date();
    const startDateTimestamp = parseDateToTimestamp(row.start_date, row.start_time);
    const endDateTimestamp = parseDateToTimestamp(row.end_date, row.end_time);
    const userLimitEdit = subtractDaysFromNow(userLimitEditConfig);

    if (startDateTimestamp > endDateTimestamp) {
      return { error: true, helperText: '' };
    }
    if (type === 'time') return true;

    const consolidationTimestamp = (now.getTime() - daysToMiliseconds(consolidationPeriod)) / 1000;
    if (startDateTimestamp < consolidationTimestamp) {
      return { error: true, helperText: translate('validation:DateCantBeLowerThanConsolidation') };
    }

    if (type === 'start_date' && parseDate(row[type]).isBefore(userLimitEdit)) {
      return { isValid: false, helperText: translate('validation:InvalidPeriodDate') };
    }

    return true;
  };

  const columns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      validate: validateDates('date'),
      customType: 'date',
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
      validate: validateDates('date'),
      customType: 'date',
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
      validate: validateDates('time'),
      customType: 'time',
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
      validate: validateDates('time'),
      customType: 'time',
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
      customType: 'numeric',
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'id_turn',
      lookup: turns.map(e => ({ id: e.id, name: `${e.turn_group_name} - ${e.name}`, active: e.active })),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      lookup: teams,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'id_operator_group',
      lookup: operatorsGroups,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'id_operator',
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookup: operators,
      lookupFilter: (e, f) => String(e.id_group) === String(f.id_operator_group)
        || String(f.id_operator_group) === '0',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentType'),
      field: 'equip_type',
      lookup: equipmentTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      customType: 'filterLookup',
      title: translate('common:EquipmentGroup'),
      field: 'id_equip_group',
      lookup: equipmentGroups,
      lookupFilter: (e, f) => String(e.id_equipament) === String(f.equip_type)
        || String(f.equip_type) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      customType: 'filterLookup',
      field: 'id_equip',
      lookup: equipments,
      lookupFilter: (e, f) => String(e.id_group) === String(f.id_equip_group)
          || String(f.id_equip_group) === '0',
    },
    {
      editable: 'always',
      title: translate('common:CodeType'),
      field: 'code_type',
      lookup: codeTypes,
      customType: 'filterLookup',
    },
    {
      title: translate('common:CodeGroup'),
      field: 'id_code_group',
      customType: 'filterLookup',
      lookup: codeGroups,
      lookupFilter: (codeGroupOption, row) => (
        String(codeGroupOption.code_type) === String(row.code_type)
        && String(row.equip_type) === String(codeGroupOption.id_equip)
      ),
    },
    {
      title: translate('common:Code'),
      field: 'code_pk',
      customType: 'filterLookup',
      lookup: codes,
      lookupKey: 'id_pk',
      lookupFilter: (codeOption, row) => {
        // No code Group Selected
        if (String(row.id_code_group) === '0') {
          return (
            String(row.code_type) === String(codeOption.code_type)
            && String(row.equip_type) === String(codeOption.id_equip)
          );
        }
        return (
          String(codeOption.id_group) === String(row.id_code_group)
          && String(row.equip_type) === String(codeOption.id_equip)
        );
      },
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      customType: 'filterLookup',
      field: 'element_id',
      lookup: elements,
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.id_element) === String(row.element_id)
        || String(row.element_id) === '0'
      ),
      field: 'point_id',
      lookup: subElements,
    },
    { editable: 'always', title: translate('common:Latitude'), field: 'latitude' },
    { editable: 'always', title: translate('common:Longitude'), field: 'longitude' },
    { editable: 'always', title: translate('common:Altitude'), field: 'altitude' },
  ];

  useEffect(() => {
    if (codeTypes.length <= 0) dispatch(getCodeTypes());
  }, [codeTypes, dispatch]);

  return (
    <>
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          cacheKey: 'daily_parts_codes',
        }}
        onAdd={newData => dispatch(newCodeItem(newData))}
        onDiscard={() => dispatch(restoreOriginalCode())}
        onApply={() => dispatch(updateCodeList(codeList, filters))}
        onChange={(newData) => {
          newData.forEach(({ code_pk: cp, ...row }) => {
            dispatch(setCodeValues({
              ...row,
              id_code: codes.find(c => cp === c.id_pk).id,
              code_pk: codes.find(c => cp === c.id_pk).id_pk,
            }));
          });
        }}
        onDelete={(newData) => {
          newData.forEach((e) => {
            dispatch(deleteCodeItem(e));
          });
        }}
        initialFormData={
          {
            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
            equip_type: filters.equipmentType,
            start_date: formatDate(newMomentDate(), true),
            end_date: formatDate(newMomentDate(), true),
            start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
            end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
          }
        }
        columns={columns}
        data={codeList.length > 0 ? codeList : []}
      />
    </>
  );
};

CodeList.propTypes = {};
CodeList.defaultProps = {};

export default withStyles(styles)(CodeList);
