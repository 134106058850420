/* eslint-disable no-param-reassign */
import React, { useEffect, Fragment, useState } from 'react';
import {
  withFormik, Form, Field, ErrorMessage,
} from 'formik';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Grid, Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { newMomentDate } from '~/utils/moment';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '../../../components/Card';
import PF2MSearchSelect from '../../../components/PF2MSearchSelect';
import PF2MSearchButton from '../../../components/PF2MSearchButton';
import PF2MClearFiltersButton from '../../../components/PF2MClearFiltersButton';
import PF2MDatepicker from '../../../components/PF2MDatepicker';
import styles from './styles';
import {
  searchTransportList,
  setFilters,
} from '~/store/dailyparts/actions';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
  getOperatorsGroups,
  getOperators,
  getMaterials,
  getElements,
  getTurns,
  getCodeGroups,
  getCodes,
  getSubElements,
  getPlatformConfiguration,
} from '~/store/manager/actions';
import PF2MButton from '~/components/PF2MButton';

const SearchForm = ({
  values,
  handleSubmit,
  classes,
  isValid,
  turns,
  equipmentTypes,
  equipmentGroups,
  equipments,
  operatorsGroups,
  operators,
  materials,
  elements,
  subElements,
  codeGroups,
  codes,
  isModal,
  onClose,
  resetForm,
}) => {
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchDailyParts, setSearchDailyParts] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      dispatch(getTurns(true));
      dispatch(getEquipmentTypes(true));
      dispatch(getEquipmentsGroups(true));
      dispatch(getEquipments(true));
      dispatch(getOperatorsGroups());
      dispatch(getOperators(true));
      dispatch(getMaterials(true));
      dispatch(getElements(true));
      dispatch(getSubElements(true));
      dispatch(getCodeGroups(true));
      dispatch(getCodes(true));
      dispatch(getPlatformConfiguration());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const renderMenuItem = (key, value, text, active) => (
    {
      label: text,
      value,
      inactiveCondition: active !== undefined && !active,
      tooltip: active !== undefined && !active ? translate('common:Inactive') : '',
    }
  );

  const renderTurns = () => [renderMenuItem('turn_0', 0, translate('common:Select'))]
    .concat(...turns
      .filter(s => s.name)
      .map(s => renderMenuItem(s.id, s.id, `${s.turn_group_name} - ${s.name}`, s.active)));

  const renderEquipmentTypes = () => [renderMenuItem('equipmentType_0', 0, translate('common:Select'))]
    .concat(...equipmentTypes
      .map(e => renderMenuItem(e.id, e.id, e.name, e.active)));

  const renderEquipmentGroups = () => [renderMenuItem('equipmentGroup_0', 0, translate('common:Select'))]
    .concat(...equipmentGroups
      .filter(e => !values.equipmentType || e.id_equipament === values.equipmentType)
      .map(e => renderMenuItem(e.id, e.id, e.name, e.active)));

  const renderEquipments = () => [renderMenuItem('equipment_0', 0, translate('common:Select'))]
    .concat(...equipments
      .filter(e => !values.equipmentGroup || e.id_group === values.equipmentGroup)
      .map(e => renderMenuItem(e.id, e.id, e.name, e.active)));

  const renderOperatorsGroups = () => [renderMenuItem('operatorGroup_0', 0, translate('common:Select'))]
    .concat(...operatorsGroups
      .filter(e => e.name)
      .map(e => renderMenuItem(e.id, e.id, e.name, e.active)));

  const renderOperators = () => [renderMenuItem('operator_0', 0, translate('common:Select'))]
    .concat(...operators
      .filter(e => e.name && (!values.operatorGroup || e.id_group === values.operatorGroup))
      .map(e => renderMenuItem(e.id_operator, e.id_operator, e.name, e.active)));

  const renderMaterials = () => [renderMenuItem('material_0', 0, translate('common:Select'))]
    .concat(...materials
      .filter(m => m.id > 0)
      .map(e => renderMenuItem(e.id, e.id, e.name, e.active)));

  const renderOrigins = () => [renderMenuItem('origin_0', 0, translate('common:Select'))]
    .concat(
      ...elements
        .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
        .sort(i => i.active * -1) // move inactive items to the end of the array
        .map(e => renderMenuItem(e.id, e.id, e.name, e.active)),
    );

  const renderSubOrigins = filterKey => [renderMenuItem('suborigin_0', 0, translate('common:Select'))]
    .concat(
      ...subElements
        .filter(e => (e.id_element === values[filterKey]))
        .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
        .sort(i => i.active * -1) // move inactive items to the end of the array
        .map(e => renderMenuItem(e.id, e.id, e.name, e.active)),
    );

  const renderCodesTypes = () => [renderMenuItem('codetypes_0', 0, translate('common:Select'))]
    .concat(
      ...[
        { id: '00', name: translate('common:CodeMaintenanceType') }, // essa linha se encontra dessa forma para que haja diferença com o valor que se encontra na origem
        { id: '1', name: translate('common:CodeOperationalStopType') },
        { id: '2', name: translate('common:CodeEffectiveType') },
        { id: '3', name: translate('common:CodeInactiveType') },
        { id: '4', name: translate('common:CodeIdleType') },
      ]
        .map(e => renderMenuItem(e.id, e.id, e.name)),
    );

  const renderCodeGroups = () => [renderMenuItem('codegroups_0', 0, translate('common:Select'))]
    .concat(
      ...codeGroups
        .filter(e => typeof values.codeType === 'string' && e.id_equip === values.equipmentType && (!values.codeType || e.code_type === parseInt(values.codeType, 10)))
        .map(e => renderMenuItem(e.id.toString(), e.id.toString(), e.name, e.active)),
    );
  const renderCodes = () => [renderMenuItem('codes_0', 0, translate('common:Select'))]
    .concat(
      ...codes
        .filter(e => typeof values.codeGroup === 'string' && e.id_equip === values.equipmentType && (!values.codeGroup || e.id_group === parseInt(values.codeGroup, 10)))
        .map(e => renderMenuItem(e.id, e.id, e.name, e.active)),
    );

  const renderDate = field => (
    <Field name={field} data-testid={field} component={PF2MDatepicker} />
  );

  const validateSelection = (form, name, value) => {
    // Seta os elementos subsequentes como 0, caso ocorra mudanca do elemento Pai
    // TO DO, fazer o mesmo para os outros elementos do modal

    if (name === 'equipmentType') {
      form.values.equipmentGroup = 0;
      form.values.equipment = 0;
    }
    if (name === 'equipmentGroup') {
      form.values.equipment = 0;
    }
    return form.setFieldValue(name, value, true);
  };

  const handleSearchDailyParts = (form, value) => {
    setSearchDailyParts(value);
    form.setFieldValue('searchDailyParts', value);
  };

  const renderSelect = (name, placeholder, children, disabled) => (
    <Fragment>
      <Field
        name={name}
        render={({ field, form }) => (
          <PF2MSearchSelect
            disabled={disabled}
            placeholder={placeholder}
            {...field}
            onChange={(e) => {
              validateSelection(form, field.name, e.target.value);
            }}
            style={{ height: 31, width: '100%' }}
          >
            {children}
          </PF2MSearchSelect>
        )}
      />
      <ErrorMessage
        name={name}
        render={msg => (
          <Typography
            color="error"
            variant="caption"
          >
            {msg}
          </Typography>
        )}
      />
    </Fragment>
  );

  const renderCheckbox = (name, label, disabled) => (
    <Fragment>
      <Field
        name={name}
        render={({ field, form }) => (
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={(
              <Checkbox
                disabled={disabled}
                checked={field.value}
                onChange={() => form.setFieldValue(field.name, !field.value)}
              />
            )}
            label={label}
          />
        )}
      />
    </Fragment>
  );

  return (
    <Form style={{ width: '100%' }}>
      <Grid container spacing={10}>
        <Grid item xs={3}>
          <Field
            name={'setSearchDailyParts'}
            render={({ form }) => (
              <Grid container spacing={0} className={classes.dailyPartsSelection}>
                <Grid item xs={4}>
                  <PF2MButton
                    className={!searchDailyParts ? classes.unSelectedButton : ''}
                    onClick={() => handleSearchDailyParts(form, true)}
                  >
                    {translate('common:DailyParts')}
                  </PF2MButton>
                </Grid>
                <Grid item xs={6}>
                  <PF2MButton
                    className={searchDailyParts ? classes.unSelectedButton : ''}
                    onClick={() => handleSearchDailyParts(form, false)}
                  >
                    {translate('common:CodeMigrations')}
                  </PF2MButton>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader icon>
              <>
                <CardIcon color="warning">
                  <SearchIcon />
                </CardIcon>
                {isModal && (
                  <CardIcon
                    onClick={onClose}
                    style={{
                      float: 'right',
                      cursor: 'pointer',
                      background: 'darkgray',
                      borderRadius: 50,
                      boxShadow: '10px 10px 50px gray',
                    }}
                  >
                    <CloseIcon />
                  </CardIcon>
                )}
              </>
            </CardHeader>
            <CardBody>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
              >
                {translate('common:Search')}
              </Typography>
              <Grid container spacing={2} className={classes.fieldsContainer}>
                <Grid item container spacing={1} xs style={{ marginBottom: '10px' }}>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:StartDate')}
                    </span>
                    {renderDate('startDate', values.startDate)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:EndDate')}
                    </span>
                    {renderDate('endDate', values.endDate)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:Shift')}
                    </span>
                    {renderSelect('turn', translate('common:Shift'), renderTurns(), !searchDailyParts)}
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs style={{ marginBottom: '10px' }}>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:EquipmentType')}
                    </span>
                    {renderSelect('equipmentType', `${translate('common:Type')}*`, renderEquipmentTypes())}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:EquipmentGroup')}
                    </span>
                    {renderSelect('equipmentGroup', translate('common:Group'), renderEquipmentGroups())}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:Equipment')}
                    </span>
                    {renderSelect('equipment', translate('common:Equipment'), renderEquipments())}
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs style={{ marginBottom: '10px' }}>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:Material')}
                    </span>
                    {renderSelect('material', translate('common:Material'), renderMaterials(), !searchDailyParts)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:OriginElement')}
                    </span>
                    {renderSelect('startOrigin', translate('common:OriginElement'), renderOrigins(), !searchDailyParts)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:OriginSubElement')}
                    </span>
                    {renderSelect('startSubOrigin', translate('common:OriginSubElement'), renderSubOrigins('startOrigin'), !searchDailyParts)}
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs style={{ marginBottom: '10px' }}>
                  <Grid item xs md={4}>
                    <span className={classes.labelSpan}>
                      {translate('common:DestinationElement')}
                    </span>
                    {renderSelect('endOrigin', translate('common:DestinationElement'), renderOrigins(), !searchDailyParts)}
                  </Grid>
                  <Grid item xs md={4}>
                    <span className={classes.labelSpan}>
                      {translate('common:DestinationSubElement')}
                    </span>
                    {renderSelect('endSubOrigin', translate('common:DestinationSubElement'), renderSubOrigins('endOrigin'), !searchDailyParts)}
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs style={{ marginBottom: '10px' }}>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:CodeType')}
                    </span>
                    {renderSelect('codeType', translate('common:CodeType'), renderCodesTypes(), !searchDailyParts)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:CodeGroup')}
                    </span>
                    {renderSelect('codeGroup', translate('common:CodeGroup'), renderCodeGroups(), !searchDailyParts)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:Code')}
                    </span>
                    {renderSelect('code', translate('common:Code'), renderCodes(), !searchDailyParts)}
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs style={{ marginBottom: '10px' }}>
                  <Grid item xs md={4}>
                    <span className={classes.labelSpan}>
                      {translate('common:OperatorGroup')}
                    </span>
                    {renderSelect('operatorGroup', translate('common:Group'), renderOperatorsGroups(), !searchDailyParts)}
                  </Grid>
                  <Grid item xs md={4}>
                    <span className={classes.labelSpan}>
                      {translate('common:Operator')}
                    </span>
                    {renderSelect('operator', translate('common:Operator'), renderOperators(), !searchDailyParts)}
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs>
                  <Grid item xs md={12}>
                    {renderCheckbox('hideInterruptedOrDeleted', translate('common:HideInterruptedOrDeletedCycles'), !searchDailyParts)}
                  </Grid>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                  <PF2MClearFiltersButton
                    onClick={() => resetForm({
                      startDate: newMomentDate(),
                      endDate: newMomentDate(),
                      turn: 0,
                      equipmentType: 0,
                      equipmentGroup: 0,
                      equipment: 0,
                      operatorGroup: 0,
                      operator: 0,
                      material: 0,
                      startOrigin: 0,
                      endOrigin: 0,
                      hideInterruptedOrDeleted: false,
                    })}
                  />
                  <PF2MSearchButton
                    onClick={handleSubmit}
                    disabled={!isValid}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

const enhanceWithFormik = withFormik({
  mapPropsToValues: ({ filters }) => ({
    startDate: filters.startDate || new Date(),
    endDate: filters.endDate || new Date(),
    turn: filters.turn || 0,
    equipmentType: filters.equipmentType || 0,
    equipmentGroup: filters.equipmentGroup || 0,
    equipment: filters.equipment || 0,
    operatorGroup: filters.operatorGroup || 0,
    operator: filters.operator || 0,
    material: filters.material || 0,
    startOrigin: filters.startOrigin || 0,
    endOrigin: filters.endOrigin || 0,
    startSubOrigin: filters.startSubOrigin || 0,
    endSubOrigin: filters.endSubOrigin || 0,
    codeType: filters.codeType || 0,
    codeGroup: filters.codeGroup || 0,
    code: filters.code || 0,
    searchDailyParts: filters.searchDailyParts || true,
    hideInterruptedOrDeleted: Boolean(filters.hideInterruptedOrDeleted),
  }),
  handleSubmit: async (values, { props }) => {
    props.setFilters({
      ...values,
      startDate: moment(values.startDate),
      endDate: moment(values.endDate),
    });

    if (!values.searchDailyParts) {
      props.history.push('/dynamic');
      return;
    }

    if (!props.isModal) {
      props.history.push('/parts/list');
    } else {
      props.onSearch();
    }
  },
  validateOnChange: true,
  validateOnBlur: true,
  isInitialValid: true,
  displayName: 'SearchForm',
  validate: ({
    equipmentType,
    startDate,
    endDate,
  }) => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const errors = {};

    if (!startDateMoment.isValid()) {
      errors.startDate = i18n.t('validation:InitialDateRequired');
    }

    if (!endDateMoment.isValid()) {
      errors.endDate = i18n.t('validation:FinalDateRequired');
    }

    if (startDateMoment.isValid() && endDateMoment.isValid()) {
      const days = endDateMoment.diff(startDateMoment, 'days');
      const searchLimit = process.env.REACT_APP_SEARCH_LIMIT_IN_DAYS || 7;
      if (days < 0) {
        errors.endDate = i18n.t('validation:EndMomentCantBeLowerThanStartMoment');
      }

      if (days > searchLimit) {
        errors.endDate = `${i18n.t('validation:SearchTimeRangeCantExceed')} ${searchLimit} ${i18n.t('common:Days').toLowerCase()}.`;
      }
    }

    const errorMessage = i18n.t('validation:RequiredField');

    if (!equipmentType) {
      errors.equipmentType = errorMessage;
    }

    return errors;
  },
})(SearchForm);

SearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  equipmentTypes: PropTypes.array,
  turns: PropTypes.array,
  equipmentGroups: PropTypes.array,
  equipments: PropTypes.array,
  operatorsGroups: PropTypes.array,
  operators: PropTypes.array,
  materials: PropTypes.array,
  elements: PropTypes.array,
  subElements: PropTypes.array,
  codeGroups: PropTypes.array,
  codes: PropTypes.array,
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
  resetForm: PropTypes.func,
};

SearchForm.defaultProps = {
  values: {},
  handleSubmit: {},
  isValid: false,
  equipmentTypes: [],
  turns: [],
  equipmentGroups: [],
  equipments: [],
  operatorsGroups: [],
  operators: [],
  materials: [],
  elements: [],
  subElements: [],
  codeGroups: [],
  codes: [],
  isModal: false,
  onClose: null,
  resetForm: null,
};

const mapStateToProps = state => ({
  filters: state.dailyParts.filters,
  equipmentTabs: state.dailyParts.equipmentTabs,
  equipmentTypes: state.manager.equipmentTypes,
  turns: state.manager.turns,
  equipmentGroups: state.manager.equipmentsGroups,
  equipments: state.manager.equipments,
  operatorsGroups: state.manager.operatorsGroups,
  operators: state.manager.operators,
  materials: state.manager.materials,
  elements: state.manager.elements,
  subElements: state.manager.subElements,
  codeGroups: state.manager.codeGroups,
  codes: state.manager.codes,
});

const mapDispatchToProps = {
  searchTransportList,
  setFilters,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(enhanceWithFormik));
