import React from 'react';
import { withStyles, Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './styles';
import PF2MScrollbar from '../PF2MScrollbar';

const groupBy = (xs, key) => xs.reduce((rv, x) => {
  // eslint-disable-next-line no-param-reassign
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});

const PF2MKanban = ({
  data, stages, stageColumn, classes, content, onClickItem,
}) => {
  const groupedData = groupBy(data, stageColumn);
  const { t: translate } = useTranslation();

  const renderColumnRows = (columnData, style) => (columnData.map(c => (
    <Paper onClick={() => onClickItem(c)} key={c.id} className={`${classes.card} ${classes[style]}`}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={`${classes.unfocused} ${classes[style]}`}>
          {`${c[content.equipType]} - ${c[content.equipGroup]}`}
        </div>
        <div className={`${classes.cardTitle} ${classes[style]}`}>{c[content.title]}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {`${c[content.startDate]} - ${c[content.startTime]}`}
      </div>
      <div>
        {c[content.codeGroup]}
        -
        {translate(`common:${c[content.codeType]}`)}
      </div>
    </Paper>
  )));

  const colStyle = {
    maxWidth: `${100 / stages.length}%`,
    flexBasis: `${100 / stages.length}%`,
  };

  const renderGridTitles = () => {
    const columns = stages.map(s => (
      <Grid
        style={colStyle}
        key={`${s.title}_title`}
        item
        className={classes.column}
      >
        <div className={classes.title}>{s.title}</div>
      </Grid>
    ));
    return columns;
  };

  const renderGrids = () => {
    const columns = stages.map(s => (
      <Grid
        style={colStyle}
        item
        key={s.title}
        className={classes.column}
      >
        {groupedData[s.id] ? renderColumnRows(groupedData[s.id], s.style) : ''}
      </Grid>
    ));
    return columns;
  };

  return (
    <div>
      <Grid container justifyContent={'space-evenly'}>
        {renderGridTitles()}
      </Grid>
      <PF2MScrollbar className={classes.scrollbar}>
        <Grid container justifyContent={'space-evenly'}>
          {renderGrids()}
        </Grid>
      </PF2MScrollbar>
    </div>
  );
};

PF2MKanban.defaultProps = {
  data: [{}],
  stages: [],
  stageColumn: 'status_id',
  content: {},
  onClickItem: () => {},
};

PF2MKanban.propTypes = {
  data: PropTypes.array,
  stages: PropTypes.array,
  classes: PropTypes.object.isRequired,
  stageColumn: PropTypes.string,
  content: PropTypes.object,
  onClickItem: PropTypes.func,
};

export default withStyles(styles)(PF2MKanban);
